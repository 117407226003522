import { defineStore } from "pinia";
import { defineComponent } from "vue";

const skins = {
    ac: {
        websiteType: "affiliate",
        affiliateCompanyName: "Purple Bay Affiliates",
        affiliateLink: "https://login.purplebayaffiliates.com/signup.php",
        originalDomain: "www.amoncasino.com",
        originalName: "Amon Casino",
        coolName: "Amon",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        testimonials: [
            {
                website: "www.nyecasino.me",
                comment: "We are really happy working with PurpleBay Affiliates and it is probably one of the strongest programs out there for online casinos. We are seeing great conversion and good player value.",
            },
            {
                website: "www.nettcasino.com",
                comment: "I can only recommend working with PurpleBay Affiliates, they have truly strong brands. We are looking forward to see this growing into a mutual beneficial partnership. They have always been a great affiliate managers from earlier partnerships.",
            },
            {
                website: "www.norskeautomater.com",
                comment: "We are really happy to be working with PurpleBay Affiliates, their conversion is strong and they have proven great player values during the period we have promoted them. The team is serviceminded and always available to help.",
            },
            {
                website: "www.kryptocasino.me",
                comment: "We love PurpleBay Affiliates, they have some of the best cryptocasinos at the moment, we can only recommend it.",
            },
            {
                website: "www.crypto-casinos.com",
                comment: "We love the products from PurpleBay Affiliates, they have great converting brands that have proven to work from several of our sources.",
            },
        ],
    },
    bo: {
        websiteType: "affiliate",
        affiliateCompanyName: "Cyber Elite Affiliates",
        affiliateLink: "https://affiliates.cybereliteaffiliates.com/signup.php",
        originalDomain: "www.bofcasino.com",
        originalName: "Bof Casino",
        coolName: "Bof",
        companyName: "Elite Cyber Services Limited",
        licenceCountry: "Belize",
        registrationNumber: "177058",
        registrationAddress: "123 Barrack Road, Belize City, Belize",
        testimonials: [
            {
                website: "www.nyacasino.se",
                comment: "Cyber Elite Affiliates have been very supportive right from the first point of contact. The professional approach is always clearly visible, making it a no-brainer to add their casino to our site.",
            },
            {
                website: "https://www.apparata.net/casino/",
                comment:
                    "Working with CyberElite Affiliates has been a seamless experience for the team at apparata.net . Their professionalism and friendly team has been extremly helpful. We are super happy working with them and look forward to continuing our partnership.",
            },
            {
                website: "www.buitenlandseonlinecasinos.org",
                comment:
                    "Cyber Elite Affiliates has been an incredible experience for us. We appreciate their approach, which helped us achieve new success levels. Their timely payments and excellent management skills set them apart from other affiliate programs. With their help, we could identify and overcome our weaknesses and turn them into strengths. We highly recommend Cyber Elite Affiliates to anyone looking for a reliable and effective affiliate program.",
            },
            {
                website: "https://casinohallen.se/casino/utlandska/",
                comment:
                    "We have been cooperating with CyberElite affiliates for a while, and we are very impressed with the partnership. They have the best Account Managers in their team, a great platform and payments are always issued. Casinohallen fully recommends Cyberelite!",
            },
            {
                website: "www.casinoszondercruksonline.com",
                comment: "Cyber Elite Affiliates has been an amazing partner for us. Their creativity and originality are what set them apart from other affiliate programs. This reliable company offers customers excellent support and quick competent answers at any time. Thanks to the individual approach, they can analyze every detail that directly affects the success of our website.",
            },
            {
                website: "https://casinoutanspelpaus.io/",
                comment:
                    "CyberElite affiliates has become an integral part of our growth. Their great attention to detail and strong dedication to our mutual success have made a significant impact on us at casinoutanspelpaus.",
            },
            {
                website: "www.onlinecasinoszonderlicentie.com",
                comment:
                    "Cyber Elite Affiliates has been instrumental in helping us achieve our goals. With their management tools, we got the long-awaited results we sought. Their qualified managers are always available to process requests and work out strategies to solve any problems we may encounter. Their effective service package has helped us make our site the best. We highly recommend Cyber Elite Affiliates to anyone looking for a reliable and effective affiliate program.",
            },
            {
                website: "www.sloterman-nl.com",
                comment: "Cyber Elite Affiliates has been an amazing partner for us. Their creativity and originality are what set them apart from other affiliate programs. This reliable company offers customers excellent support and quick competent answers at any time. Thanks to the individual approach, they can analyze every detail that directly affects the success of our website.",
            },
            {
                website: "www.onlinecasinometvolt.com",
                comment:
                    "Cyber Elite Affiliates has been an incredible experience for us. We appreciate their approach, which helped us achieve new success levels. Their timely payments and excellent management skills set them apart from other affiliate programs. With their help, we could identify and overcome our weaknesses and turn them into strengths. We highly recommend Cyber Elite Affiliates to anyone looking for a reliable and effective affiliate program.",
            },
            {
                website: "www.safecasinoshub.com",
                comment:
                    "Cyber Elite Affiliates is a great addition to our partners team, working with them is an amazing experience, they are always willing to help you and improving.",
            },
            {
                website: "www.utansvensklicens.casino",
                comment:
                    "Cyber Elite Affiliates are great to work with. Their affiliate managers are committed and help new affiliate members. We are very happy to cooperate with the Cybereliteaffiliates affiliate team and their brand.",
            },
            {
                website: "www.casinoutanspelpaus.io",
                comment:
                    "Working with CyberElite Affiliates has been a game-changer for us at CasinoutanSpelpaus.io. Their team is incredibly professional and always available to assist with any needs. The deals and promotions they offer are top-notch. We highly recommend working with them!",
            },
        ],
    },
    lj: {
        websiteType: "affiliate",
        affiliateCompanyName: "Prime Web Affiliates",
        affiliateLink: "https://affiliates.primewebaffiliates.com/signup.php",
        originalDomain: "www.letsjackpot.com",
        originalName: "Lets Jackpot",
        coolName: "Lets Jackpot",
        companyName: "Prime Web OPS SRL",
        licenceCountry: "Anjouan",
        registrationNumber: "3-102-904748",
        registrationAddress: "PROVINCE 01 SAN JOSE, CANTON 08 GOICOECHEA, CALLE BLANCOS, MONTELIMAR, Costa Rica",
        testimonials: [],
    },
    sp: {
        websiteType: "company",
        companyName: "SPOP OPS SOCIEDAD DE RESPONSABILIDAD LIMITADA",
        licenceCountry: "Costa Rica",
        registrationNumber: "4062001322405.00",
        registrationAddress: "San Miguel de Santo Domingo de Heredi, 40303, Santo Domingo, Heredia, Costa Rica",
        email: "admin@spopops.com",
    },
    ab: {
        websiteType: "company",
        companyName: "Above & Beyond IS",
        licenceCountry: "Limassol Cyprus",
        registrationNumber: "HE357005",
        registrationAddress: "Georgiou Katsounotou, 6, 3036, Limassol Cyprus",
        email: "finance@aboveandbeyondintegratedsolutions.com",
        contactNumber: "+35722053893",
    },
};

export const useConfigStore = defineStore("config", {
    state: () => ({
        config: null,
    }),
    actions: {
        initConfig(skin) {
            const initialConfigObject = {
                skin,
                websiteType: skins[skin].websiteType,
                affiliateCompanyName: skins[skin].affiliateCompanyName,
                affiliateLink: skins[skin].affiliateLink,
                originalDomain: skins[skin].originalDomain,
                originalName: skins[skin].originalName,
                coolName: skins[skin].coolName,
                companyName: skins[skin].companyName,
                licenceCountry: skins[skin].licenceCountry,
                registrationNumber: skins[skin].registrationNumber,
                registrationAddress: skins[skin].registrationAddress,
                testimonials: skins[skin].testimonials,
                email: skins[skin].email,
                contactNumber: skins[skin].contactNumber,
            };
            this.config = Object.assign({}, this.config, initialConfigObject);
        },
    },
    persist: true,
});
